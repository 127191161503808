<template>
    <div style="max-width: 20rem; margin: 0 auto;">
        
        <b-form @submit="signUp" @reset="onReset" v-if="show">
            <b-form-group id="input-group-3"
                          label="Email address:"
                          label-for="input-3"
                          description="We'll never share your email with anyone else.">
                <b-form-input id="input-3"
                              v-model="form.email"
                              type="email"
                              required
                              placeholder="Enter email"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-4" label="New Password:" label-for="input-2">
                <b-form-input id="input-4"
                              v-model="form.password"
                              type="password"
                              required
                              placeholder="Select password"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-3" label="Your Name:" label-for="input-2">
                <b-form-input id="input-4"
                              v-model="form.name"
                              type="text"
                              required
                              placeholder="Enter name"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-5" label="Your Telephone number:" label-for="input-2">
                <b-form-input id="input-5"
                              v-model="form.phone_number"
                              type="tel"
                              required
                              placeholder="Enter telephone number"></b-form-input>
            </b-form-group>

            <b-button class="mr-2" type="submit" variant="primary">Sign Up</b-button>
            <b-button type="reset" variant="warning">Reset</b-button>
        </b-form>
        
    </div>
</template>

<script>
    import { Auth } from 'aws-amplify'

    export default {
        name: 'signup',
        props: ['toggle'],
        data() {
            return {
                formState: 'signUp',
                show: true,
                form: {
                    username: '',
                    password: '',
                    email: '',
                    phone_number: '',
                    name: '',
                    authCode: ''
                }
            }
        },
        methods: {
            async signUp() {
                const { password, email, phone_number, name } = this.form
                const username = email
                await Auth.signUp({
                    username, password, attributes: { email, phone_number, name }
                })
                    .then(() => {
                        alert('successfully signed up! Please note that you have to confirm your e-mail adress by clicking the verification link in the e-mail you will receive shortly.')
                        if (this.$router.path !== '/signin') this.$router.push('/signin')
                        // this.formState = 'confirmSignUp'
                    })
                    .catch(err => alert(JSON.stringify(err, null, 2)));

            },
            
            onReset(evt) {
                evt.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.password = ''
                this.form.phone_number = ''
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                    this.show = true
                })
            }
        }
    }
</script>

<style scoped>

</style>