<template>
    <div>
        <b-navbar fixed="top" class="flex-container px-3 py-2" type="dark" variant="primary">
            <div style="width:30px" href="#">
                <img v-if="!loading" src="@/assets/l4a-logo-symbol.png" style="width:30px" />
                <b-spinner v-if="loading" type="grow" style="color:rgb(256, 153, 0);" label="Loading..."></b-spinner>
            </div>
            <div>
                <span class="w-auto align-middle text-center h5" style="color:rgb(256, 153, 0);">{{ currentpage }}</span>
            </div>
            <div>
                <b-icon v-if="alert" icon="exclamation-triangle" variant="danger" class="mr-1 align-right h5 mt-2"></b-icon>
                <burger class="float-right"></burger>
            </div>
        </b-navbar>
    </div>
</template>

<script>
    import Burger from '@/components/Menu/Burger';
    export default {
        name: 'NavBar',
        data() {
            return {
                
            };
        },
        computed: {
            currentpage() {
                return this.$store.getters.currentPage
            },
            loading() {
                return (this.$store.getters.loadingCount > 0)
            },
            alert() {
                let alerts = this.$store.getters.alerts
                return alerts.findIndex(x => x.alertStatus == 'alert') > -1 ? true : false
            }
        },
        methods: {
            openMenu() {
                this.$store.commit('openMenu')
            }
        },
        components: {
            burger: Burger
        },
        watch: {
            '$route': function (value, oldVal) {
                if (value.name == 'Dashboard') {
                    const id = this.$route.params.id;
                    this.$store.commit('updateCurrentPage', this.$store.getters.dashboards.filter(function (c) {
                        return c.id === id;
                    })[0].name);
                    let dbid = this.$store.getters.dashboards.filter(function (c) {
                        return c.id === id;
                    })[0].id;
                    this.$store.commit('updateDbVisible', true)
                    this.$store.commit('updateActiveDb', dbid)
                    if (oldVal.name == 'Dashboard') {  // If previous page was not a dashboard, the data will be loaded when the Dashboard component is loaded.
                        let dbindex = this.$store.getters.dashboards.findIndex(x => x.id === this.$store.getters.activeDb)
                        if (typeof dbindex !== 'undefined' && dbindex !== -1) {
                            let payload = {
                                start: this.$store.getters.timePeriod.periodStart,
                                end: this.$store.getters.timePeriod.periodEnd,
                                storageIds: this.$store.getters.dashboards[dbindex].storageIds
                            }
                            this.$store.dispatch('updateData', payload)
                            this.$store.dispatch('updateStatistics', payload)
                        }
                    }
                } else {
                    this.$store.commit('updateCurrentPage', value.name);
                    this.$store.commit('updateDbVisible', false)
                }
            }
        }
    };
</script>

<style scoped>
    .flex-container {
        display: flex;
        justify-content: space-between;
    }

        .flex-container > div {
            text-align: center;
        }
</style>