<template>
    <div ref="sideNav" class="bm-menu">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'menubar',
        data() {
            return {
                isSideBarOpen: false
            };
        },
        props: {
            isOpen: {
                type: Boolean,
                required: false
            },
            right: {
                type: Boolean,
                required: false
            },
            width: {
                type: [String],
                required: false,
                default: '300'
            },
            disableEsc: {
                type: Boolean,
                required: false
            },
            noOverlay: {
                type: Boolean,
                required: false
            },
            onStateChange: {
                type: Function,
                required: false
            },
            burgerIcon: {
                type: Boolean,
                required: false,
                default: true
            },
            crossIcon: {
                type: Boolean,
                required: false,
                default: true
            },
            disableOutsideClick: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        methods: {
            openMenu() {
                this.$emit('openMenu');
                this.isSideBarOpen = true;

                if (!this.noOverlay) {
                    document.body.classList.add('bm-overlay');
                }
                if (this.right) {
                    this.$refs.sideNav.style.left = 'auto';
                    this.$refs.sideNav.style.right = '0px';
                }
                this.$nextTick(function () {
                    this.$refs.sideNav.style.width = this.width
                        ? this.width + 'px'
                        : '200px';
                });
            },

            newCloseMenu() {
                this.$store.commit('closeMenu')
            },

            closeMenu() {
                this.$emit('closeMenu');
                this.isSideBarOpen = false;
                document.body.classList.remove('bm-overlay');
                this.$refs.sideNav.style.width = '0px';

            },

            closeMenuOnEsc(e) {
                e = e || window.event;
                if (e.key === 'Escape' || e.keyCode === 27) {
                    this.newCloseMenu();
                }
            },
            documentClick(e) {
                //console.log(e.target.type)
                if (
                    e.target.className !== 'bm-menu' &&
                    !e.target.classList.contains('menu-open') &&
                    !e.target.classList.contains('custom-control-label') &&
                    !e.target.classList.contains('custom-control-input') &&
                    e.target.type !== 'button' &&
                    this.isSideBarOpen &&
                    !this.disableOutsideClick
                ) {
                    this.newCloseMenu();
                }
            }
        },

        mounted() {
            if (!this.disableEsc) {
                document.addEventListener('keyup', this.closeMenuOnEsc);
            }

        },
        created: function () {
            document.addEventListener('click', this.documentClick);

        },
        destroyed: function () {
            document.removeEventListener('keyup', this.closeMenuOnEsc);
            document.removeEventListener('click', this.documentClick);
        },
        watch: {
            isOpen: {
                deep: true,
                immediate: true,
                handler(newValue, oldValue) {
                    this.$nextTick(() => {
                        if (!oldValue && newValue) {
                            this.openMenu();
                        }
                        if (oldValue && !newValue) {
                            this.closeMenu();
                        }
                    });
                }
            },
            right: {
                deep: true,
                immediate: true,
                handler(oldValue) {
                    if (oldValue) {
                        this.$nextTick(() => {
                            this.$refs.sideNav.style.left = 'auto';
                            this.$refs.sideNav.style.right = '0px';
                            document.querySelector('.bm-menu').style.left = 'auto';
                            document.querySelector('.bm-menu').style.right = '0px';
                        });
                    }
                }
            }
        }
    };
</script>

<style scoped>

    html {
        height: 100%;
    }

    .bm-menu {
        height: 100%; /* 100% Full-height */
        width: 0; /* 0 width - change this with JavaScript */
        position: fixed; /* Stay in place */
        z-index: 1000; /* Stay on top */
        top: 0;
        left: 0;
        background-color: #3a3f44; /* Darkslategray */
        overflow-x: hidden; /* Disable horizontal scroll */
        padding-top: 60px; /* Place content 60px from the top */
        transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
    }

    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
    }

    .bm-item-list {
        color: #7A8288;
    }

    /*.bm-item-list > * {
            display: flex;
            text-decoration: none;
            padding: 0.7em;
        }

            .bm-item-list > * > span {
                margin-left: 10px;
                font-weight: 700;
                color: white;
            }*/
</style>

