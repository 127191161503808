// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:daaa2cca-7a46-4ea1-bb4a-b2888ef63d7b",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Pq56KjdMH",
    "aws_user_pools_web_client_id": "4rf57j054jrce9en3nm6d5ckim",
    "oauth": {}
};


export default awsmobile;
