<template>
    <b-container>
        <b-row>
            <b-col>
                <label for="newNameInput">Dashboard name:</label>
                <b-form-input class="menu-open"
                              id="newNameInput"
                              type="text"
                              v-model="name"
                              :state="nameState"
                              aria-describedby="input-live-feedback"
                              placeholder="New dashboard name"
                              size="sm"></b-form-input>
                <b-form-invalid-feedback id="input-live-feedback">
                    Name must have at least 3 characters and not already be used.
                </b-form-invalid-feedback>
            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col>
                <b-button class="menu-open" v-on:click="setNewName" :disabled="!nameState" variant="secondary" size="sm">Change name</b-button>
            </b-col>
        </b-row>
        <b-row class="mt-1">
            <b-col>
                <b-button class="menu-open" v-on:click="createNewDb" :disabled="!nameState" variant="secondary" size="sm">Create new DB</b-button>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col>
                <b-form-select class="menu-open" v-model="newComp" :options="compOptions" :select-size="1"></b-form-select>
                <b-button class="menu-open mr-2 mt-1" v-on:click="addComponent" variant="secondary" size="sm">Add component</b-button>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col>
                <b-button class="mr-2" v-on:click="removeDb" variant="secondary" size="sm">Remove active DB</b-button>
            </b-col>
        </b-row>
        <b-row class="mt-3">
            <b-col>
                <b-button class="mr-2" v-on:click="cancelDbChanges" variant="secondary" size="sm">Cancel</b-button>
                <b-button v-on:click="saveDbChanges" variant="primary" size="sm">Save</b-button>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    import { bus } from '@/main.js';
    import { dbComponents } from '@/assets/l4a_jsAssets.js'
    export default {
        data() {
            return {
                windowWidth: 0,
                name: '',
                boxConfirm: false,
                newComp: '',
                layout: [],
                cols: 12
            };
        },
        computed: {
            nameState() {
                let index = this.$store.getters.dashboards.findIndex(x => x.name === this.name);

                return this.name.length > 2 && index == -1 ? true : false
            },
            editDbMode() {
                return this.$store.getters.editDbMode;
            },
            compOptions() {
                let c = [];
                //let availableComponents = this.$store.getters.dbComponents
                let availableComponents = dbComponents
                for (let component of availableComponents) {
                    c.push({ text: component.name, value: component.id })
                }
                return c
            }
        },
        methods: {
            addComponent() {
                let compIndex = dbComponents.findIndex(x => x.id === this.newComp)
                let payload = {
                    dbId: this.$store.getters.activeDb,
                    x: 0,
                    y: 0,
                    newComp: dbComponents[compIndex]
                }
                this.$store.commit('addComp', payload)
                bus.$emit('layoutUpdated')
            },
            saveDbChanges() {
                this.$store.commit('editDbMode', false);
                this.$store.dispatch('updateUserConfig')
                let dbindex = this.$store.getters.dashboards.findIndex(x => x.id === this.$store.getters.activeDb)
                if (typeof dbindex !== 'undefined' && dbindex !== -1) {
                    let payload = {
                        //start: this.periodStart,
                        //end: this.periodEnd,
                        storageIds: this.$store.getters.dashboards[dbindex].storageIds
                    }
                    this.$store.dispatch('updateData', payload)
                    this.$store.dispatch('updateStatistics', payload)
                    this.$store.commit('closeMenu')
                }
                //bus.$emit('newData') // To force refresh of dashboard components series
            },
            cancelDbChanges() {
                this.$store.commit('editDbMode', false);
                this.$store.commit('rolebackDbChanges', this.$store.getters.activeDb);
                this.$store.commit('closeMenu')
            },
            createNewDb() {
                this.$store.commit('addDashboard', this.name)
                this.name = ''
                this.showSuccess('The new dashboard was created successfully')
            },
            removeDb() {
                this.showConfirm()

            },
            setNewName() {
                this.$store.commit('changeDbName', { id: this.$store.getters.activeDb, name: this.name })
                this.name = ''
                this.showSuccess('The name was changed successfully')
            },
            showConfirm() {
                this.boxConfirm = false
                this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this dashboard.', {
                    title: 'Please Confirm',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: 'YES',
                    cancelTitle: 'NO',
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                    .then(value => {
                        if (value) {
                            this.$store.commit('removeDashboard', this.$store.getters.activeDb)
                        }
                    })
                    .catch(err => {
                        // An error occurred
                        alert(err)
                    })
            },
            showSuccess(message) {
                this.$bvModal.msgBoxOk(message, {
                    title: 'Confirmation',
                    size: 'sm',
                    buttonSize: 'sm',
                    okVariant: 'success',
                    headerClass: 'p-2 border-bottom-0',
                    footerClass: 'p-2 border-top-0',
                    centered: true
                })
                    .then()
                    .catch(err => {
                        alert(err)
                    })
            }
        },
        mounted: function () {
            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been rendered
                this.newComp = dbComponents[0].name
            })
        }
    }
</script>

<style scoped>
</style>