<template>
    <div>
        <b-form-group>
            <b-form-tags v-model="value" no-outer-focus class="mb-0 bg-transparent border-0">
                <template class="d-flex flex-row" v-slot="{ tags, disabled, addTag, removeTag }">
                    <b-row>
                        <b-col cols="5">
                            <b-dropdown size="sm" variant="secondary">
                                <template v-slot:button-content>
                                    {{buttonText}}
                                </template>
                                <b-dropdown-form @submit.stop.prevent="() => {}">
                                    <!--<b-form-group label-for="tag-search-input"
                                                  label="Search"
                                                  label-cols-md="auto"
                                                  class="mb-0"
                                                  label-size="sm"
                                                  :description="searchDesc"
                                                  :disabled="disabled">
                                        <b-form-input v-model="search"
                                                      type="search"
                                                      size="sm"
                                                      autocomplete="off"></b-form-input>
                                    </b-form-group>-->
                                </b-dropdown-form>
                                <!--<b-dropdown-divider></b-dropdown-divider>-->
                                <b-dropdown-item-button v-for="option in availableOptions"
                                                        :key="option"
                                                        @click="onOptionClick({ option, addTag })">
                                    {{ option }}
                                </b-dropdown-item-button>
                                <b-dropdown-text v-if="availableOptions.length === 0">
                                    There are no tags available to select
                                </b-dropdown-text>
                            </b-dropdown>
                        </b-col>
                        <b-col cols="7">
                            <ul v-if="tags.length > 0" class="list-inline d-inline-block">
                                <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                    <b-form-tag @remove="removeTag(tag)"
                                                :title="tag"
                                                :disabled="disabled"
                                                variant="info">{{ tag }}</b-form-tag>
                                </li>
                            </ul>
                        </b-col>
                    </b-row>
                </template>
            </b-form-tags>
        </b-form-group>
    </div>
</template>

<script>
    export default {
        props: ['options', 'selected', 'buttonText'],
        data() {
            return {
                search: ''
            }
        },
        computed: {
            value: {
                // getter
                get: function () {
                    return this.selected
                },
                // setter
                set: function (newValue) {
                    this.$emit('update:selected', newValue)
                }
            },
            criteria() {
                // Compute the search criteria
                return this.search.trim().toLowerCase()
            },
            availableOptions() {
                const criteria = this.criteria
                // Filter out already selected options
                const options = this.options.filter(opt => this.value.indexOf(opt) === -1)
                if (criteria) {
                    // Show only options that match criteria
                    return options.filter(opt => opt.toLowerCase().indexOf(criteria) > -1);
                }
                // Show all options available
                return options
            },
            searchDesc() {
                if (this.criteria && this.availableOptions.length === 0) {
                    return 'There are no tags matching your search criteria'
                }
                return ''
            }
        },
        methods: {
            onOptionClick({ option, addTag }) {
                addTag(option)
                this.search = ''
            }
        },
        //watch: {
        //    value: function (value) {
        //        console.log(value)
        //        this.$emit('update:selected', value)
        //    }
        //}
    }
</script>

<style scoped>
</style>