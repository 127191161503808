<template>
    <div class="my-bm-style">
        <Slide width="200" right :isOpen="menuOpen ? true : false">
            <b-nav>
                <b-nav-item to="/">
                    <font-awesome-icon icon="home" size="lg" />
                </b-nav-item>
                <b-nav-item-dropdown id="my-nav-dropdown-db" v-if="signedIn" text="Dashboards" menu-class="scrollable-menu" toggle-class="nav-link-custom">
                    <template slot="button-content">
                        <font-awesome-icon icon="chart-line" size="lg" />
                    </template>
                    <b-dropdown-item v-for="dashboard in dashboards" :key="dashboard.id" :to="'/dashboards/' + dashboard.id">{{ dashboard.name }}</b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item to="/signin" v-if="!signedIn">
                    <font-awesome-icon icon="sign-in-alt" size="lg" />
                </b-nav-item>
                <b-nav-item-dropdown id="my-nav-dropdown-user" v-if="signedIn" text="User" toggle-class="nav-link-custom">
                    <template slot="button-content">
                        <font-awesome-icon icon="cog" />
                    </template>
                    <b-dropdown-item to="/profile">Profile</b-dropdown-item>
                    <b-dropdown-item to="/settings" v-if="!editDbMode">Settings</b-dropdown-item>
                    <b-dropdown-item to="/newpassword" v-if="signedIn">New Password</b-dropdown-item>
                    <b-dropdown-item v-on:click='signOut' v-if="signedIn">Logout</b-dropdown-item>
                    <b-dropdown-item to="/protected">Development</b-dropdown-item>
                </b-nav-item-dropdown>
            </b-nav>
            <b-row align-h="center">
                <b-col cols="8" align-self="center">
                    <b-button @click="startEditDb" class="mt-4" v-if="!editDbMode && signedIn && dbVisible" block variant="primary" size="sm">Edit dashboard</b-button>
                </b-col>
            </b-row>

            <hr style="border:1px solid gray" />
            <b-form v-if="dbVisible && !editDbMode" class="mx-3">
                <p>
                    Time period:<br />
                    {{periodStart.toLocaleDateString()}} {{periodStart.toLocaleTimeString()}}<br />
                    {{periodEnd.toLocaleDateString()}} {{periodEnd.toLocaleTimeString()}}
                </p>
                <b-form-group>
                    <label for="endDate">Period end:</label>
                    <b-form-input id="endDate" class="menu-open" v-model="endDate" @change="handleNewDate" type="date" size="sm"></b-form-input>
                    <b-form-input class="menu-open mt-1" v-model="endTime" @change="handleNewTime" type="time" size="sm"></b-form-input>
                    <b-row class="mt-2">
                        <b-col cols="7" align-self="center">
                            <b-button @click="latest" class="mb-4" block variant="primary" size="sm">Latest  <font-awesome-icon v-if="ubeSocketOpen" icon="sync-alt" size="sm" :style="syncSymbolStyle" /></b-button>
                            <b-button @click="today" class="mb-1" block variant="primary" size="sm">Today</b-button>
                            <b-button @click="addPeriod" class="mb-1" block variant="primary" size="sm">+ Period</b-button>
                            <b-button @click="subPeriod" class="mb-1" block variant="primary" size="sm">- Period</b-button>
                            <b-button @click="update" class="mt-4" block variant="primary" size="sm">Update</b-button>
                        </b-col>
                        <b-col cols="5">
                            <label for="selectPeriod">Period:</label>
                            <b-form-radio-group id="selectPeriod"
                                                v-model="period"
                                                :options="periodOptions"
                                                @change="handleNewPeriod"
                                                size="sm"
                                                name="radios-stacked"
                                                stacked>
                            </b-form-radio-group>
                        </b-col>
                    </b-row>
                </b-form-group>
            </b-form>
            <editdb v-if="editDbMode && dbVisible"></editdb>
            <settingssb v-if="settingsActive"></settingssb>
        </Slide>
    </div>
</template>

<script>
    import Slide from '@/components/Menu/slide.vue'  // import the CSS transitions you wish to use, in this case we are using `Slide`
    import EditDashboard from '@/components/Dashboard/editDashboard.vue'
    import SettingsSB from '@/components/Settings/settingsSideBar.vue'
    import { AmplifyEventBus } from 'aws-amplify-vue'
    import { Auth } from 'aws-amplify'
    import { ubeWsOpen, ubeWsClose } from '@/main.js';
    //import Amplify from 'aws-amplify';
    export default {
        data() {
            return {
                signedIn: false,
                dashboards: this.$store.getters.dashboards,
                autoUpdate: 'no',
                lastEnd: null,
                updateTimer: '',
                period: 24,
                periodOptions: [
                    { text: '1h', value: 1 },
                    { text: '3h', value: 3 },
                    { text: '6h', value: 6 },
                    { text: '12h', value: 12 },
                    { text: '24h', value: 24 },
                    { text: '7d', value: 168 },
                    { text: '1m', value: 720 },
                    { text: '1y', value: 8760 }
                ]
            };
        },
        computed: {
            editDbMode() {
                return this.$store.getters.editDbMode;
            },
            dbVisible() {
                return this.$store.getters.dbVisible;
            },
            pLatest() {
                return this.$store.getters.latest;
            },
            settingsActive() {
                if (this.$store.getters.currentPage == "Settings") {
                    return true
                } else {
                    return false
                }
            },
            menuOpen() { return this.$store.getters.menuOpen },
            endDate: {
                get: function () { return new Date(this.$store.getters.timePeriod.periodEnd).toLocaleDateString() },
                set: function () { }
            },
            endTime: {
                get: function () { return new Date(this.$store.getters.timePeriod.periodEnd).toLocaleTimeString() },
                set: function () { }
            },
            periodStart() {
                return new Date(this.$store.getters.timePeriod.periodStart)
            },
            periodEnd() {
                return new Date(this.$store.getters.timePeriod.periodEnd)
            },
            ubeSocketOpen() {
                return this.$store.getters.ubeSocketOpen;
            },
            syncSymbolStyle() {
                return {
                    'color': '#3cb371'
                }

            },
            loading() {
                return (this.$store.getters.loadingCount > 0)
            }
        },
        methods: {
            async signOut() {
                //ubeWsClose(); // disconnecting websocket
                await Auth.signOut();
                this.signedIn = false;
                AmplifyEventBus.$emit('authState', 'signedOut')
                this.$store.reset()
                //this.$store.commit("resetState");
            },
            handle1Ok() {
                this.$store.commit('addDashboard', this.form.name)
                this.form.name = ''
            },
            startEditDb() {
                this.$store.commit('editDbMode', true);
                this.$store.commit('copyDashboards');
            },
            handleNewDate(event) {
                this.$store.commit('updateTimePeriod', { periodEnd: new Date(event + " " + this.endTime), period: this.period, add: 0 })
                this.$store.commit('latest', false)
            },
            handleNewTime(event) {
                this.$store.commit('updateTimePeriod', { periodEnd: new Date(this.endDate + " " + event), period: this.period, add: 0 })
                this.$store.commit('latest', false)
            },
            handleNewPeriod(event) {
                let period = event
                console.log(period)
                this.$store.commit('updateTimePeriod', { periodEnd: new Date(this.endDate + " " + this.endTime), period: period, add: 0 })
            },
            latest() {
                this.$store.commit('updateTimePeriod', { periodEnd: new Date(), period: this.period, add: 0 })
                let dbindex = this.$store.getters.dashboards.findIndex(x => x.id === this.$store.getters.activeDb)
                if (typeof dbindex !== 'undefined' && dbindex !== -1) {
                    let payload = {
                        storageIds: this.$store.getters.dashboards[dbindex].storageIds
                    }
                    this.$store.commit('latest', true)
                    this.$store.dispatch('updateData', payload)
                    this.$store.dispatch('updateStatistics', payload)
                    this.$store.commit('closeMenu')
                }
            },
            today() {
                let now = new Date()
                this.$store.commit('updateTimePeriod', { periodEnd: new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59), period: this.period, add: 0 })
                let dbindex = this.$store.getters.dashboards.findIndex(x => x.id === this.$store.getters.activeDb)
                if (typeof dbindex !== 'undefined' && dbindex !== -1) {
                    let payload = {
                        storageIds: this.$store.getters.dashboards[dbindex].storageIds
                    }
                    this.$store.commit('latest', false)
                    this.$store.dispatch('updateData', payload)
                    this.$store.dispatch('updateStatistics', payload)
                    this.$store.commit('closeMenu')
                }
            },
            addPeriod() {
                this.$store.commit('updateTimePeriod', { periodEnd: this.periodEnd, period: this.period, add: 1 })
                let dbindex = this.$store.getters.dashboards.findIndex(x => x.id === this.$store.getters.activeDb)
                if (typeof dbindex !== 'undefined' && dbindex !== -1) {
                    let payload = {
                        storageIds: this.$store.getters.dashboards[dbindex].storageIds
                    }
                    this.$store.commit('latest', false)
                    this.$store.dispatch('updateData', payload)
                    this.$store.dispatch('updateStatistics', payload)
                    this.$store.commit('closeMenu')
                }
            },
            subPeriod() {
                this.$store.commit('updateTimePeriod', { periodEnd: this.periodEnd, period: this.period, add: -1 })
                let dbindex = this.$store.getters.dashboards.findIndex(x => x.id === this.$store.getters.activeDb)
                if (typeof dbindex !== 'undefined' && dbindex !== -1) {
                    let payload = {
                        storageIds: this.$store.getters.dashboards[dbindex].storageIds
                    }
                    this.$store.commit('latest', false)
                    this.$store.dispatch('updateData', payload)
                    this.$store.dispatch('updateStatistics', payload)
                    this.$store.commit('closeMenu')
                }
            },
            update() {
                let dbindex = this.$store.getters.dashboards.findIndex(x => x.id === this.$store.getters.activeDb)
                if (typeof dbindex !== 'undefined' && dbindex !== -1) {
                    let payload = {
                        storageIds: this.$store.getters.dashboards[dbindex].storageIds
                    }
                    this.$store.dispatch('updateData', payload)
                    this.$store.dispatch('updateStatistics', payload)
                    this.$store.commit('closeMenu')
                }
            }
        },
        beforeCreate() {
            AmplifyEventBus.$on('authState', info => {
                if (info === 'signedIn') {
                    this.signedIn = true
                    this.$store.dispatch('getUserConfig')
                    this.dashboards = this.$store.getters.dashboards
                    if (this.$router.currentRoute.path !== '/profile') this.$router.push('/profile')
                }
                if (info === 'signedOut') {
                    this.signedIn = false
                    this.$store.commit('signedOut')
                    if (this.$router.currentRoute.path !== '/signin') this.$router.push('/signin')
                }
            });
            console.log(this.$router.currentRoute.path)
            Auth.currentAuthenticatedUser()
                .then(() => {
                    this.signedIn = true
                    this.$store.dispatch('getUserConfig')
                    this.dashboards = this.$store.getters.dashboards
                    //if (this.$router.currentRoute.path !== '/') this.$router.push('/')
                })
                .catch(() => {
                    this.signedIn = false
                    if (this.$router.currentRoute.path !== '/') this.$router.push('/')
                })
        },
        mounted() {
            if (this.dbVisible && this.pLatest && !this.ubeSocketOpen) ubeWsOpen();
        },
        watch: {
            dbVisible: function () {
                console.log('dbVisbible: ' + this.dbVisible + ' pLatest: ' + this.pLatest + ' ubeSocketOpen: ' + this.ubeSocketOpen)
                if (this.dbVisible && this.pLatest && !this.ubeSocketOpen) ubeWsOpen();
                if (!(this.dbVisible && this.pLatest) && this.ubeSocketOpen) ubeWsClose();
            },
            pLatest: function () {
                console.log('dbVisbible: ' + this.dbVisible + ' pLatest: ' + this.pLatest + ' ubeSocketOpen: ' + this.ubeSocketOpen)
                if (this.dbVisible && this.pLatest && !this.ubeSocketOpen) ubeWsOpen();
                if (!(this.dbVisible && this.pLatest) && this.ubeSocketOpen) ubeWsClose();
            }
        },
        components: {
            Slide, // Register your component
            editdb: EditDashboard,
            settingssb: SettingsSB
        }
    }
</script>

<style>
</style>