import { bus } from '@/main.js';
import Amplify from 'aws-amplify';
//import { max } from 'lodash';

function DbDefinition(id, name) {
    return { id: id, name: name, layout: [], storageIds: [] }
}

async function getData(start, end, storageIds) {
    Amplify.Auth.currentAuthenticatedUser()
        .then(async () => {
            bus.$emit('loading', true)
            try {
                let body = {
                    userId: '4b6d59f7-82b0-40ae-9e13-03bea93ee30b', // To be removed. Omly for testing!! Must also change in Lambda function to use API GW mapped userId.
                    placeIdList: storageIds,
                    startTime: new Date(start),
                    endTime: new Date(end),
                    statDate: start,
                    now: new Date()
                }
                //console.log(body)
                let data = await Amplify.API.post("l4a-cbe", '/dev/getData_v4', { body: body })
                bus.$emit('loading', false)
                console.log(data)
                return data
            } catch (err) {
                console.log(err)
                bus.$emit('loading', false)
            }

        })
        .catch(err => console.log(JSON.stringify(err, null, 2)))
}

async function aggregateStatistics(datas, statistics, statTime) {
    let now = new Date();
    let localStartOfDay = new Date(now);
    localStartOfDay.setHours(0, 0, 0, 0);
    let stat = statistics;
    //console.log("stat: (before) " + JSON.stringify(stat, null, 2))
    
    for (let data of datas) {
        let storage = data.placeId;
        let filteredHcData = data.hcData.filter(d => d[0] > localStartOfDay.getTime());
        if (filteredHcData.length > 0) {
            filteredHcData = filteredHcData.reverse();  // to get the latest occurence of min max values
            let tempArray = filteredHcData.map(el => el[1])
            let minIndex = tempArray.findIndex(x => x == Math.min(...tempArray));
            let maxIndex = tempArray.findIndex(x => x == Math.max(...tempArray));
            const average = (array) => array.reduce((a, b) => a + b) / array.length;
            let minV = filteredHcData[minIndex][1];
            let minT = filteredHcData[minIndex][0];
            let maxV = filteredHcData[maxIndex][1];
            let maxT = filteredHcData[maxIndex][0];
            let avgV = average(tempArray);
            let numV = tempArray.length;
            //console.log("filteredHcData: " + JSON.stringify(filteredHcData, null, 2))
            if (stat.findIndex(x => x.placeId === storage) > -1) {
                if (statTime.getFullYear() == now.getFullYear() && statTime.getMonth() == now.getMonth() && statTime.getDay() == now.getDay()) {
                    let newStat = {
                        count: numV,
                        avgValue: avgV,
                        minValue: minV,
                        minTime: minT,
                        maxValue: maxV,
                        maxTime: maxT
                    };
                    stat.find(x => x.placeId === storage).statDay = newStat;
                }

                if (statTime.getFullYear() == now.getFullYear() && statTime.getMonth() == now.getMonth()) {
                    if (typeof stat.find(x => x.placeId === storage).statMonth.count === 'undefined' || stat.find(x => x.placeId === storage).statMonth.count == 0) {
                        let newStat = {
                            count: numV,
                            avgValue: avgV,
                            minValue: minV,
                            minTime: minT,
                            maxValue: maxV,
                            maxTime: maxT
                        };
                        stat.find(x => x.placeId === storage).statMonth = newStat;
                    } else {
                        if (minV <= stat.find(x => x.placeId === storage).statMonth.minValue) {
                            stat.find(x => x.placeId === storage).statMonth.minValue = minV;
                            stat.find(x => x.placeId === storage).statMonth.minTime = minT;
                        }
                        if (maxV >= stat.find(x => x.placeId === storage).statMonth.maxValue) {
                            stat.find(x => x.placeId === storage).statMonth.maxValue = maxV;
                            stat.find(x => x.placeId === storage).statMonth.maxTime = maxT;
                        }
                        stat.find(x => x.placeId === storage).statMonth.avgValue = (stat.find(x => x.placeId === storage).statMonth.avgValue * stat.find(x => x.placeId === storage).statMonth.count + avgV * numV) / (stat.find(x => x.placeId === storage).statMonth.count + numV);
                        stat.find(x => x.placeId === storage).statMonth.count += numV;
                    }
                }

                if (statTime.getFullYear() == now.getFullYear()) {
                    if (typeof stat.find(x => x.placeId === storage).statYear.count === 'undefined' || stat.find(x => x.placeId === storage).statYear.count == 0) {
                        let newStat = {
                            count: numV,
                            avgValue: avgV,
                            minValue: minV,
                            minTime: minT,
                            maxValue: maxV,
                            maxTime: maxT
                        };
                        stat.find(x => x.placeId === storage).statYear = newStat;
                    } else {
                        if (minV <= stat.find(x => x.placeId === storage).statYear.minValue) {
                            stat.find(x => x.placeId === storage).statYear.minValue = minV;
                            stat.find(x => x.placeId === storage).statYear.minTime = minT;
                        }
                        if (maxV >= stat.find(x => x.placeId === storage).statYear.maxValue) {
                            stat.find(x => x.placeId === storage).statYear.maxValue = maxV;
                            stat.find(x => x.placeId === storage).statYear.maxTime = maxT;
                        }
                        stat.find(x => x.placeId === storage).statYear.avgValue = (stat.find(x => x.placeId === storage).statYear.avgValue * stat.find(x => x.placeId === storage).statYear.count + avgV * numV) / (stat.find(x => x.placeId === storage).statYear.count + numV);
                        stat.find(x => x.placeId === storage).statYear.count += numV;
                    }
                }
            }
        }
        
    }
    //console.log("stat: (after) " + JSON.stringify(stat, null, 2))
    return stat;
}

function isInSelectedCollectors(storage) {
    if (this.changedSources.findIndex(x => x.placeId === storage.placeId) > -1) {
        let loggerName = this.changedCollectors.find(x => x.loggerId === this.changedSources.find(x => x.placeId === storage.placeId).loggerId).loggerName
        if (this.selectedCollectors.length == 0) {
            return true // include all storages if no collector is selected
        } else {
            return this.selectedCollectors.indexOf(loggerName) >= 0
        }
    } else {
        return true // including also Storages with no assigned source
    }

}

function isInSelectedTypes(storage) {
    if (this.changedSources.findIndex(x => x.placeId === storage.placeId) > -1) {
        let type = this.changedSources.find(x => x.placeId === storage.placeId).originalId.split("_")[0]
        if (this.selectedTypes.length == 0) {
            return true // include all storages if no type is selected
        } else {
            return this.selectedTypes.indexOf(type) >= 0
        }
    } else {
        if (this.selectedTypes.length == 0) {
            return true // include all storages if no type is selected
        } else {
            return false
        }
    }
}

const hcChartTmpl = {
    "chart": {
        backgroundColor: null,
        width: null,
        height: "56.25%",
        zoomType: "x"
    },
    legend: {
        enabled: true,
        itemStyle: {
            color: "#eaeaea"
        },
        itemHoverStyle: {
            color: "#ffffff"
        }
    },
    plotOptions: {
        area: {
            fillColor: {
                linearGradient: {
                    x1: 0,
                    x2: 0,
                    y1: 0,
                    y2: 1
                },
                stops: [
                    [
                        0,
                        "#7cb5ec"
                    ],
                    [
                        1,
                        "rgba(124,181,236,0)"
                    ]
                ]
            },
            lineWidth: 1,
            marker: {
                radius: 2
            },
            states: {
                hover: {
                    lineWidth: 1
                }
            },
            threshold: null
        },
        series: {
            marker: {
                enabled: false
            }
        }
    },
    time: {
        useUTC: false
    },
    title: {
        style: {
            fontSize: "14px",
            color: "#eaeaea"
        },
        text: "Chart title"
    },
    tooltip: {
        crosshairs: true,
        shared: false,
        split: false
    },
    xAxis: {
        type: "datetime",
        lineColor: "#ccd6eb",
        gridLineColor: "#e6e6e6",
        tickColor: "#ccd6eb",
        labels: {
            style: {
                color: "#eaeaea"
            }
        }
    },
    yAxis: [
        {
            index: 0,
            labels: {
                style: {
                    color: "#eaeaea"
                }
            },
            title: {
                text: null
            },
            lineColor: "#ccd6eb",
            gridLineColor: "#e6e6e6"
        },
        {
            index: 1,
            labels: {
                style: {
                    color: "#eaeaea"
                }
            },
            opposite: true,
            title: {
                text: null
            },
            lineColor: "#ccd6eb",
            gridLineColor: "#e6e6e6"
        }
    ],
    series: []
}

const axChart1Tmpl = {
    chart: {
        type: 'area',
        stacked: false,
        height: '100%',
        width: '100%',
        zoom: {
            type: 'xy',
            enabled: true,
            autoScaleYaxis: true
        },
        toolbar: {
            autoSelected: 'zoom'
        },
        animations: {
            dynamicAnimation: {
                enabled: false
            }
        },
        redrawOnParentResize: true,
        background: '#ffffff00'
    },
    colors: [],
    dataLabels: {
        enabled: false
    },
    grid: {
        borderColor: '#eaeaea'
    },
    legend: {
        labels: {
            useSeriesColors: true
        },
        markers: {

        }
    },
    markers: {
        size: 0,
    },
    title: {
        text: 'tbd',
        align: 'left',
        style: {
            color: '#eaeaea'
        }
    },
    plotOptions: {
        area: {
            fillTo: 'end'
        }
    },
    fill: {
        type: 'gradient',
        gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.5,
            opacityTo: 0,
            stops: [0, 90, 100]
        },
    },
    yaxis: [],
    xaxis: {
        type: 'datetime',
        axisBorder: {
            color: '#eaeaea'
        },
        axisTicks: {
            color: '#eaeaea'
        },
        labels: {
            datetimeUTC: false,
            style: {
                colors: '#eaeaea'
            }
        },
        tooltip: {
            enabled: false
        }
    },
    tooltip: {
        shared: false,
        y: {
            formatter: function (val) {
                return val
            }
        },
        x: {
            format: 'yy-MM-dd HH:mm:ss'
        }
    },
    //responsive: {
    //    breakpoint: 575,
    //    options: {
    //        chart: {
    //            zoom: {
    //                enabled: false
    //            }
    //        }
    //    }
    //},
    noData: {
        text: 'Loading...',
        align: 'center',
        verticalAlign: 'middle',
        offsetX: 0,
        offsetY: 0,
        style: {
            color: '#ff9900',
            fontSize: '14px',
            fontFamily: undefined
        }
    }
    //series: []
}

const hcGauge1Tmpl = {
    chart: {
        type: 'gauge',
        animation: false,
        backgroundColor: null,
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false
    },
    title: {
        text: null,
        margin: 0,
        style: {
            color: '#eaeaea'
        }
    },
    subtitle: {
        text: null,
        style: {
            color: '#eaeaea',
            widthAdjust: 0,
            fontSize: '9px'
        }
    },
    pane: {
        startAngle: -120,
        endAngle: 120,
        background: [{
            backgroundColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                    [0, '#FFF'],
                    [1, '#333']
                ]
            },
            borderWidth: 0,
            outerRadius: '109%'
        }, {
            backgroundColor: {
                linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
                stops: [
                    [0, '#333'],
                    [1, '#FFF']
                ]
            },
            borderWidth: 1,
            outerRadius: '107%'
        }, {
            // default background
        }, {
            backgroundColor: '#DDD',
            borderWidth: 0,
            outerRadius: '105%',
            innerRadius: '103%'
        }]
    },

    // the value axis
    yAxis: {
        min: -40,
        max: 40,

        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: '#666',

        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 15,
        tickColor: '#666',
        labels: {
            step: 2,
            rotation: 'auto'
        },
        title: {
            text: 'tbd'
        },
        plotBands: [{
            from: -40,
            to: 0,
            color: '#3399ff', // blue
            thickness: 15
        }, {
            from: 0,
            to: 40,
            color: '#ff3300', // red
            thickness: 15
        }]
    },

    series: [
        {
            name: 'Waiting...',
            data: [0]
        }
    ]
}

const hcGauge2Tmpl = {
    chart: {
        type: 'solidgauge',
        animation: false,
        backgroundColor: null,
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false
    },

    title: {
        text: null,
        y: 10,
        floating: true,
        margin: 0,
        style: {
            color: '#eaeaea'
        }
    },
    /*subtitle: {
        text: null,
        style: {
            color: '#eaeaea',
            widthAdjust: 0,
            fontSize: '9px'
        }
    },*/
    pane: {
        center: ['50%', '85%'],
        size: '100%',
        startAngle: -90,
        endAngle: 90,
        background: {
            backgroundColor: '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
        }
    },

    exporting: {
        enabled: false
    },

    tooltip: {
        enabled: false
    },

    // the value axis
    yAxis: {
        stops: [
            [0.1, '#55BF3B'], // green
            [0.5, '#DDDF0D'], // yellow
            [0.9, '#DF5353'] // red
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
            y: -50
        },
        labels: {
            distance: '80%',
            style: {
                color: "#eaeaea"
            },
            y: 16
        }
    },

    plotOptions: {
        solidgauge: {
            dataLabels: {
                y: -20,
                borderWidth: 0,
                useHTML: true
            }
        }
    },
    series: [
        {
            name: 'Waiting...',
            data: [0]
        }
    ]
}

const cgGauge3Tmpl = {
    //renderTo: "to_be_set", // Might have to be changed!!!
    width: 80,
    height: 80,
    units: "˚C",
    title: false,
    value: 0,
    minValue: -40,
    maxValue: 40,
    majorTicks: [
        '-40', '-30', '-20', '-10', '0', '10', '20', '30', '40'
    ],
    minorTicks: 2,
    strokeTicks: false,
    highlights: [
        { from: -40, to: 0, color: 'rgba(51,153,255,1)' },
        { from: 0, to: 40, color: 'rgba(255,51,0,1)' }
    ],
    colorPlate: '#eee',
    colorMajorTicks: '#ddd',
    colorMinorTicks: '#eee',
    colorTitle: '#fff',
    colorUnits: '#222',
    colorNumbers: '#222',
    colorNeedle: 'rgba(240, 128, 128, 1)',
    colorNeedleEnd: 'rgba(255, 160, 122, .9)',
    valueBox: true,
    valueBoxWidth: 15,
    valueBoxStroke: 1,
    valueInt: 2,
    valueDec: 1,
    animationRule: 'elastic',
    animationDuration: 500
}

const display1Tmpl = {
    options: {}
}

const defConfTemplates = [
    { name: '_Orange', type: 'Chart', tmpl: { opt: { xLabelColor: "#ff9915", axisColors: "#ff9915", titleColor: "#eaeaea", y1LabelColor: "#ff9915", gridColor: "#b75b00", legendColor: "#eaeaea", title: "tbd", y2LabelColor: "#ff9915" } } },
    { name: '_Blue', type: 'Chart', tmpl: { opt: { xLabelColor: "#01afee", axisColors: "#01afee", titleColor: "#eaeaea", y1LabelColor: "#01afee", gridColor: "#016D99", legendColor: "#eaeaea", title: "tbd", y2LabelColor: "#01afee" } } },
    { name: '_Orange', type: 'Chart2', tmpl: { opt: { xLabelColor: "#ff9915", axisColors: "#ff9915", titleColor: "#eaeaea", y1LabelColor: "#ff9915", gridColor: "#b75b00", legendColor: "#eaeaea", title: "tbd", y2LabelColor: "#ff9915" } } },
    { name: '_Blue', type: 'Chart2', tmpl: { opt: { xLabelColor: "#01afee", axisColors: "#01afee", titleColor: "#eaeaea", y1LabelColor: "#01afee", gridColor: "#016D99", legendColor: "#eaeaea", title: "tbd", y2LabelColor: "#01afee" } } },
    { name: "_Temperature", type: "Gauge", tmpl: { opt: { plotBands: [{ color: "#3399fd", from: -40, id: "be6dc62d-cff8-4b9e-8620-7c9a42f754af", thickness: 15, to: 0 }, { color: "#ff3300", from: 0, id: "1993c007-6628-464e-acbb-d76e4813d0b9", thickness: 15, to: 40 }], scaleFrom: -40, scaleTo: 40, ticks: { incr: 10, start: -40 }, title: "tbd", titleColor: "#eaeaea" } } },
    { name: "_Humidity", type: "Gauge", tmpl: { opt: { plotBands: [{ color: "#a3e0ff", from: 0, id: "11408c40-c64a-4fab-bc87-ae2c110c47b4", thickness: 15, to: "100" }], scaleFrom: 0, scaleTo: 10, ticks: { incr: 10, start: 0 }, title: "tbd", titleColor: "#eaeaea" } } }
]

const dbComponents = [
    { name: 'Chart', id: 'e1dd54e7-97ca-427c-beaf-8d79a7f6fd73', file: 'chart1_hc.vue', w: 6, h: 8, config: { storages: [] }, series: [] },
    { name: 'Chart2', id: '007fb992-50df-415a-aa4f-23aef305f41d', file: 'chart2_ax.vue', w: 6, h: 8, config: { storages: [] }, series: [] },
    { name: 'Gauge', id: '871045b8-f162-49c4-b645-06bc01827c64', file: 'gauge3_cg.vue', w: 1, h: 5, config: { storages: [] }, series: [] },
    { name: 'Display', id: '6a62aca5-7b7e-4b0b-8412-9c69f54f69d8', file: 'display1.vue', w: 1, h: 2, config: { storages: [] }, series: [] },
    { name: 'Solidgauge', id: '023b8a65-539d-46c7-ae81-22dec4c55113', file: 'gauge2_hc.vue', w: 1, h: 4, config: { storages: [] }, series: [] }
]

const l4aColors = ['rgba(255, 153, 0, 1)', 'rgba(6, 164, 222, 1)']

export {
    DbDefinition, 
    getData,
    hcChartTmpl,
    axChart1Tmpl,
    hcGauge1Tmpl,
    hcGauge2Tmpl,
    cgGauge3Tmpl,
    display1Tmpl,
    defConfTemplates,
    dbComponents,
    l4aColors,
    isInSelectedCollectors,
    isInSelectedTypes,
    aggregateStatistics
};