import { DbDefinition, defConfTemplates, l4aColors } from '@/assets/l4a_jsAssets.js';
import { uuid } from 'vue-uuid';
import { router } from '@/main.js';
import Amplify from 'aws-amplify';
import Vue from 'vue';


const config = {
    state: {
        dashboards: [
            {
                id: 'test',
                name: 'test',
                storageIds: [],
                layout: []
            }
        ],
        sources: [],
        storages: [],
        collectors: [],
        metrics: [],
        alerts: [],
        dbCopy: [],
        confTemplates: [],
        editDbMode: false,
        sourcesChanged: false,
        breakPoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
        dbSize: 'lg',
        user: 'tbd',
        userAttributes: {},
        appUrl: 'https://dev.log4all.com',
        filter: {
            selectedCollectors: [],
            selectedTypes: [],
            selectedLabels: [],
            unusedSources: false,
        },
        editStorageSelectedCollectors: [],
        editStorageSelectedTypes: [],
        presetColors: []
    },
    mutations: {
        addDashboard(state, name) {
            let newId = name.replace(/\s/g, "_");
            let newDashboard = new DbDefinition(newId, name);
            state.dashboards.push(newDashboard);
            state.dashboards.sort(function (a, b) { return a.id - b.id });
            router.push('/dashboards/' + newId)
        },
        removeDashboard(state, id) {
            let index = state.dashboards.findIndex(x => x.id === id);
            state.dashboards.splice(index, 1);
            if (state.dashboards.length == index && index > 0) {
                router.push('/dashboards/' + state.dashboards[index - 1].id)
            } else if (state.dashboards.length == 0) {
                let newDashboard = new DbDefinition("My_First_Dashboard", "My First Dashboard");
                state.dashboards.push(newDashboard);
                state.dashboards.sort(function (a, b) { return a.id - b.id });
                router.push('/dashboards/My_First_Dashboard')
            } else {
                router.push('/dashboards/' + state.dashboards[index].id)
            }
        },
        copyDashboards(state) {
            state.dbCopy = JSON.parse(JSON.stringify(state.dashboards));
        },
        rolebackDbChanges(state, id) {
            state.dashboards.splice(0, state.dashboards.length, ...state.dbCopy);
            let index = state.dashboards.findIndex(x => x.id === id);
            if (index === -1) {
                router.push('/dashboards/' + state.dashboards[0].id)
            }
        },
        changeDbName(state, payload) {
            let index = state.dashboards.findIndex(x => x.id === payload.id);
            if (index != -1) {
                state.dashboards[index].id = payload.name.replace(/\s/g, "_")
                state.dashboards[index].name = payload.name
                router.push('/dashboards/' + payload.name.replace(/\s/g, "_"))
            }
        },
        updateCurrentPage(state, page) {
            state.currentPage = page;
        },
        addComp(state, payload) {
            console.log(payload)
            let dbInd = state.dashboards.findIndex(x => x.id === payload.dbId);
            // Creat a new component
            let id = uuid.v4()
            let newItem = {
                i: id,
                name: payload.newComp.name,
                file: payload.newComp.file,
                config: payload.newComp.config,
                xxs: {
                    i: id,
                    x: 0,
                    y: 0,
                    w: payload.newComp.w,
                    h: payload.newComp.h
                },
                xs: {
                    i: id,
                    x: 0,
                    y: 0,
                    w: payload.newComp.w,
                    h: payload.newComp.h
                },
                sm: {
                    i: id,
                    x: 0,
                    y: 0,
                    w: payload.newComp.w,
                    h: payload.newComp.h
                },
                md: {
                    i: id,
                    x: 0,
                    y: 0,
                    w: payload.newComp.w,
                    h: payload.newComp.h
                },
                lg: {
                    i: id,
                    x: 0,
                    y: 0,
                    w: payload.newComp.w,
                    h: payload.newComp.h
                }
            }
            // Check first what row that is the last in order to place the new component on a new row.
            let sizes = ['xxs', 'xs', 'sm', 'md', 'lg']
            for (let item of state.dashboards[dbInd].layout) {
                for (let size of sizes) {
                    if (item[size].y + item[size].h >= newItem[size].y) newItem[size].y = item[size].y + item[size].h
                }
            }
            // Add the new component to the active dashboard
            state.dashboards[dbInd].layout.splice(state.dashboards[dbInd].layout.length, 0, newItem);
        },
        deleteComp(state, payload) {
            let dbInd = state.dashboards.findIndex(x => x.id === payload.dbid);
            let itemInd = state.dashboards[dbInd].layout.findIndex(x => x.i === payload.itemid);
            // Delete the selected component
            state.dashboards[dbInd].layout.splice(itemInd, 1);
        },
        updateCompConfig(state, payload) {
            let dbInd = state.dashboards.findIndex(x => x.id === payload.dbid);
            let itemInd = state.dashboards[dbInd].layout.findIndex(x => x.i === payload.itemid);
            Vue.set(state.dashboards[dbInd].layout[itemInd], 'config', JSON.parse(JSON.stringify(payload.newConfig)));
        },
        updateCompLayouts(state, payload) {
            let dbInd = state.dashboards.findIndex(x => x.id === payload.dbid);
            for (let item of payload.layout) {
                let itemInd = state.dashboards[dbInd].layout.findIndex(x => x.i === item.i);
                let newDef = {
                    i: item.i,
                    x: item.x,
                    y: item.y,
                    w: item.w,
                    h: item.h
                }
                Vue.set(state.dashboards[dbInd].layout[itemInd], state.dbSize, newDef);
            }
        },
        editDbMode(state, status) {
            state.editDbMode = status;
        },
        setConfig(state, config) {
            console.log(config.config);
            (typeof config.config !== 'undefined' && typeof config.config.dashboards_v2 !== 'undefined') ? state.dashboards.splice(0, state.dashboards.length, ...config.config.dashboards_v2) : state.dashboards.splice(0, state.dashboards.length);
            // add first dashboard if none exist
            if (config.config.dashboards_v2.length == 0) {
                let newDashboard = new DbDefinition("My_First_Dashboard", "My First Dashboard");
                state.dashboards.push(newDashboard);
                state.dashboards.sort(function (a, b) { return a.id - b.id });
                router.push('/dashboards/My_First_Dashboard')
            }
            if (typeof config.config !== 'undefined' && typeof config.config.confTemplates !== 'undefined') {
                if (config.config.confTemplates.length > 0) {
                    state.confTemplates.splice(0, state.confTemplates.length, ...config.config.confTemplates);
                }
            }
            state.sources.splice(0, state.sources.length, ...config.sensors);
            state.storages.splice(0, state.storages.length, ...config.places);
            state.metrics.splice(0, state.metrics.length, ...config.metrics);
            state.collectors.splice(0, state.collectors.length, ...config.loggers);
            state.alerts.splice(0, state.alerts.length, ...config.alerts);
        },
        signedOut(state) {
            state.dashboards.splice(0, state.dashboards.length);
            state.sources.splice(0, state.sources.length);
            state.storages.splice(0, state.storages.length);
            state.metrics.splice(0, state.metrics.length);
            state.collectors.splice(0, state.collectors.length);
            state.confTemplates.splice(0, state.confTemplates.length);
            state.dbCopy.splice(0, state.dbCopy.length);
        },
        updateStorageIds(state, dbId) {
            let dbindex = state.dashboards.findIndex(x => x.id === dbId)
            let storageIds = []
            for (let layout of state.dashboards[dbindex].layout) {
                for (let storage of layout.config.storages) {
                    let index = storageIds.findIndex(x => x === storage)
                    if (index == -1) storageIds.push(storage)
                }
            }
            state.dashboards[dbindex].storageIds.splice(0, state.dashboards[dbindex].storageIds.length, ...storageIds)
        },
        addConfTemplate(state, payload) {
            if (state.confTemplates.length == 0) state.confTemplates = defConfTemplates
            state.confTemplates.splice(state.confTemplates.length, 0, { name: payload.name, type: payload.type, tmpl: payload.tmpl })
        },
        updateConfTemplate(state, payload) {
            if (state.confTemplates.length == 0) state.confTemplates = defConfTemplates
            console.log(payload)
            state.confTemplates.find(x => x.name === payload.name).tmpl = payload.tmpl
        },
        setDbSize(state, dbSize) {
            state.dbSize = dbSize
        },
        updateSourceConfig(state, payload) {
            state.sources = payload
        },
        updateSourcesChanged(state, payload) {
            state.sourcesChanged = payload
        },
        updateStorageConfig(state, payload) {
            state.storages = payload
        },
        updateMetricsConfig(state, payload) {
            state.metrics = payload
        },
        updateCollectorsConfig(state, payload) {
            state.collectors = payload
        },
        updateAlertsConfig(state, payload) {
            state.alerts = payload
        },
        updateFilter(state, payload) {
            state.filter = payload
        },
        //updateSelectedCollectors(state, payload) {
        //    state.selectedCollectors = payload
        //},
        //updateSelectedTypes(state, payload) {
        //    state.selectedTypes = payload
        //},
        //updateSelectedLabels(state, payload) {
        //    state.selectedLabels = payload
        //},
        //updateShowOnlyUnusedSources(state, payload) {
        //    state.showOnlyUnusedSources = payload
        //},
        updateEditStorageSelectedCollectors(state, payload) {
            state.editStorageSelectedCollectors = payload
        },
        updateEdiStorageSelectedTypes(state, payload) {
            state.editStorageSelectedTypes = payload
        },
        setUser(state, user) {
            state.user = user
        },
        setUserAttributes(state, attributes) {
            state.userAttributes = attributes
        },
        updatePresetColors(state, payload) {
            if (!state.presetColors.find(x => x === payload) && !l4aColors.find(x => x === payload)) {
                state.presetColors.unshift(payload)
                while (state.presetColors.length + l4aColors.length > 16) state.presetColors.pop()
            }
        }
    },
    actions: {
        async getUserConfig({ commit }) {
            Amplify.Auth.currentAuthenticatedUser()
                .then(async () => {
                    //bus.$emit('loading', true)
                    commit('loadingStarted')
                    try {
                        let config = await Amplify.API.get("l4a-cbe", '/dev/getUserConfig_v3')
                        commit('setConfig', config)
                        //bus.$emit('loading', false)
                        commit('loadingEnded')
                    } catch (err) {
                        console.log(err)
                        //bus.$emit('loading', false)
                        commit('loadingEnded')
                    }

                })
                .catch(err => console.log(JSON.stringify(err, null, 2)))

        },
        async updateUserConfig({ getters, commit }) {
            Amplify.Auth.currentAuthenticatedUser()
                .then(async () => {
                    //bus.$emit('loading', true)
                    commit('loadingStarted')
                    try {
                        let body = {
                            config: {
                                "dashboards_v2": getters.dashboards,
                                "confTemplates": getters.confTemplatesWithoutDef
                            },
                            sensors: getters.sources,
                            places: getters.storages,
                            metrics: getters.metrics,
                            loggers: getters.collectors,
                            alerts: getters.alerts,
                            sensorsChanged: getters.sourcesChanged,
                            presetColors: getters.presetColors
                        }
                        await Amplify.API.post("l4a-cbe", '/dev/updateUserConfig_v3', { body: body })
                        //bus.$emit('loading', false)
                        // Change any alerts.new = true to false and remove deleted sensors and alerts after the updated backend config
                        let alerts = JSON.parse(JSON.stringify(getters.alerts))
                        let sources = JSON.parse(JSON.stringify(getters.sources))
                        //console.log('Source to be removed: ' + sources.find(x => x.remove == true).sensorId)
                        while (alerts.findIndex(x => x.new == true) > -1) alerts.find(x => x.new == true).new = false
                        while (alerts.findIndex(x => x.remove == true) > -1) alerts.splice(alerts.findIndex(x => x.remove == true), 1)
                        while (sources.findIndex(x => x.remove == true) > -1) sources.splice(sources.findIndex(x => x.remove == true), 1)
                        commit('updateAlertsConfig', alerts)
                        commit('updateSourceConfig', sources)
                        commit('loadingEnded')
                    } catch (err) {
                        console.log(err)
                        //bus.$emit('loading', false)
                        commit('loadingEnded')
                    }

                })
                .catch(err => console.log(JSON.stringify(err, null, 2)))
        }
    },
    getters: {
        dashboards: function (state) {
            return state.dashboards
        },
        sources: function (state) {
            return state.sources
        },
        storages: function (state) {
            return state.storages
        },
        collectors: function (state) {
            return state.collectors
        },
        metrics: function (state) {
            return state.metrics
        },
        alerts: function (state) {
            return state.alerts
        },
        dbCopy: function (state) {
            return state.dbCopy
        },
        editDbMode: function (state) {
            return state.editDbMode
        },
        sourcesChanged: function (state) {
            return state.sourcesChanged
        },
        confTemplatesWithoutDef: function (state) {
            return state.confTemplates
        },
        confTemplates: function (state) {
            return state.confTemplates.concat(defConfTemplates)
            //return state.confTemplates.length == 0 ? defConfTemplates : state.confTemplates  // returning default configuration templates if no are defined in the store.
        },
        breakPoints: function (state) {
            return state.breakPoints
        },
        dbSize: function (state) {
            return state.dbSize
        },
        user: function (state) {
            return state.user
        },
        userAttributes: function (state) {
            return state.userAttributes
        },
        appUrl: function (state) {
            return state.appUrl
        },
        filter: function (state) {
            return state.filter
        },
        //selectedCollectors: function (state) {
        //    return state.selectedCollectors
        //},
        //selectedTypes: function (state) {
        //    return state.selectedTypes
        //},
        //selectedLabels: function (state) {
        //    return state.selectedLabels
        //},
        //showOnlyUnusedSources: function (state) {
        //    return state.showOnlyUnusedSources
        //},
        editStorageSelectedCollectors: function (state) {
            return state.editStorageSelectedCollectors
        },
        editStorageSelectedTypes: function (state) {
            return state.editStorageSelectedTypes
        },
        presetColors: function (state) {
            return state.presetColors.concat(l4aColors)
        }
    }
}

export default config