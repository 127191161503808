<template>
    <div class="componentWrapper p-0" style="width: 100%; height: 100%">
        <component :is="componentLoader" :dbid="dbid" :itemid="itemid" :comp="comp" style="width: 100%; height: 100%" @removed="removed">
        </component>
    </div>
</template>
<script>
    export default {
        name: 'component-wrapper',
        props: ['dbid', 'comp', 'itemid'],
        computed: {
            componentLoader() {
                return () => import("@/components/Dashboard/" + this.comp.file)
            }
        },
        methods: {
            removed(payload) {
                this.$emit('removed', payload)
            }
        }
    }
</script>

<style scoped>
</style>