<template>
    <div style="max-width: 20rem; margin: 0 auto;">

        <b-form @submit="signIn" v-if="show">
            <b-form-group id="input-group-1"
                          label="Email address:"
                          label-for="input-1"
                          description="We'll never share your email with anyone else.">
                <b-form-input id="input-1"
                              v-model="form.email"
                              type="email"
                              required
                              placeholder="Enter email"></b-form-input>
            </b-form-group>

            <b-form-group class="mb-0" id="input-group-2" label="Your Password:" label-for="input-2">
                <b-form-input id="input-2"
                              v-model="form.password"
                              type="password"
                              required
                              placeholder="Enter password"></b-form-input>
            </b-form-group>
            <b-nav class="px-0 py-0 mt-0 mx-0">
                <b-nav-item class="ml-0 pl-0" link-classes="pl-0 text-info" to="/forgotpw">I forgot my password</b-nav-item>
                <b-nav-item link-classes="text-info" to="/signup">Sign Up</b-nav-item>
            </b-nav>

            <b-button class="mt-2" type="submit" variant="primary">Sign In</b-button>

        </b-form>
    </div>
</template>

<script>
    import { bus } from '@/main.js';
    import { Auth } from 'aws-amplify'
    import { AmplifyEventBus } from 'aws-amplify-vue'
    //import Amplify from 'aws-amplify';
    export default {
        name: 'signin',
        data() {
            return {
                show: true,
                form: {
                    email: '',
                    password: ''
                }
            }
        },
        methods: {
            async signIn() {
                event.preventDefault()
                const { email, password } = this.form
                bus.$emit('loading', true)
                await Auth.signIn(email, password)
                    .then(() => {
                        AmplifyEventBus.$emit('authState', 'signedIn')
                        Auth.currentAuthenticatedUser()
                            .then(user => {
                                this.$store.commit('setUser', user.username);
                                this.$store.commit('setUserAttributes', user.attributes)
                            })
                            .catch(() => console.log('not signed in...'))
                    })
                    .catch(err => alert(JSON.stringify(err, null, 2)));
                bus.$emit('loading', false);

                //Amplify.Auth.currentAuthenticatedUser()
                //    .then(async () => {
                //        try {
                //            let data = await Amplify.API.post("l4a-cbe", '/dev/createWsTicket', { body: {} })
                //            //console.log('createWsTicket reply: ' + data.ticket)
                //            this.$connect('wss://d0zs7kfe30.execute-api.eu-central-1.amazonaws.com/dev?ticket=' + data.ticket, { format: 'json' })
                //            //console.log('Websocket Connection opened!')
                //        } catch (err) {
                //            console.log(err)
                //        }

                //    })
                //    .catch(err => console.log(JSON.stringify(err, null, 2)))
            }
        }
    }
</script>

<style scoped>
</style>