<template>
    <div>
        <b-icon class="h3" icon="filter" :style="{cursor: 'pointer', color: filterActive ? '#ff9900' : '#02abe9'}" @click="editFilter=!editFilter"></b-icon>
        <b-modal :title="'Resource filter'" v-model="editFilter" @hide="handleEditStorageEvents">
            <b-container>
                <b-row>
                    <b-col>
                        <tags-selection :options="collectorsOptions" :selected.sync="selectedCollectors" buttonText="Collectors"></tags-selection>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <tags-selection :options="typesOptions" :selected.sync="selectedTypes" buttonText="Source type"></tags-selection>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <tags-selection :options="labelsOptions" :selected.sync="selectedLabels" buttonText="Storage labels"></tags-selection>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-checkbox name="checkbox-unusedSources"
                                         size="sm"
                                         v-model="unusedSources">
                            Only unused sources
                        </b-form-checkbox>
                    </b-col>
                </b-row>
            </b-container>
            <template #modal-footer="{ ok, hide }">
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <b-button variant="secondary" size="sm" @click="hide('reset')">
                    Reset
                </b-button>
                <b-button variant="primary" size="sm" @click="ok()">
                    Done
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import TagsSelection from '@/components/Settings/tagsSelection.vue'
    export default {
        data() {
            return {
                editFilter: false,
                selectedCollectors: [],
                selectedTypes: [],
                selectedLabels: [],
                unusedSources: false
            };
        },
        computed: {
            filter() {
                return this.$store.getters.filter
            },
            newFilter() {
                return {
                    selectedCollectors: this.selectedCollectors,
                    selectedTypes: this.selectedTypes,
                    selectedLabels: this.selectedLabels,
                    unusedSources: this.unusedSources
                }
            },
            filterActive() {
                return this.selectedCollectors.length > 0 || this.selectedTypes.length > 0 || this.selectedLabels.length > 0 || this.unusedSources
            },
            collectorsOptions() {
                let options = []
                for (let collector of this.$store.getters.collectors) {
                    options.push(collector.loggerName)
                }
                return options
            },
            typesOptions() {
                let types = []
                for (let source of this.$store.getters.sources) {

                    let s = source.originalId.split("_")
                    if (types.indexOf(s[0]) === -1) types.push(s[0])
                }
                console.log(types)
                return types
            },
            labelsOptions() {
                let labels = []
                for (let storage of this.$store.getters.storages) {
                    if (typeof storage.labels !== 'undefined') {
                        for (let label of storage.labels) {
                            if (labels.indexOf(label) === -1) labels.push(label)
                        }
                    }
                }
                console.log(labels)
                return labels
            }
        },
        methods: {
            handleEditStorageEvents(event) {
                switch (event.trigger) {
                    case 'reset': {
                        this.selectedCollectors = []
                        this.selectedTypes = []
                        this.selectedLabels = []
                        this.unusedSources = false
                        break;
                    }
                }
            }
        },
        watch: {
            newFilter: {
                handler: function (newValue) {
                    this.$store.commit('updateFilter', newValue)
                },
                deep: true
            },
            filter: {
                handler: function (newValue) {
                    newValue && newValue.selectedCollectors ? this.selectedCollectors = newValue.selectedCollectors : this.selectedCollectors = []
                    newValue && newValue.selectedTypes ? this.selectedTypes = newValue.selectedTypes : this.selectedTypes = []
                    newValue && newValue.selectedLabels ? this.selectedLabels = newValue.selectedLabels : this.selectedLabels = []
                    newValue && typeof newValue.unusedSources !== 'undefined' ? this.unusedSources = newValue.unusedSources : this.unusedSources = false
                },
                deep: true
            }
        },
        mounted: function () {
            this.filter && this.filter.selectedCollectors ? this.selectedCollectors = this.filter.selectedCollectors : this.selectedCollectors = []
            this.filter && this.filter.selectedTypes ? this.selectedTypes = this.filter.selectedTypes : this.selectedTypes = []
            this.filter && this.filter.selectedLabels ? this.selectedLabels = this.filter.selectedLabels : this.selectedLabels = []
            this.filter && typeof this.filter.unusedSources !== 'undefined' ? this.unusedSources = this.filter.unusedSources : this.unusedSources = false
        },
        components: {
            'tags-selection': TagsSelection
        }
    }
</script>

<style scoped>
</style>