const menu = {
    state: {
        menuOpen: false
    },
    mutations: {
        openMenu(state) {
            state.menuOpen = true
        },
        closeMenu(state) {
            state.menuOpen = false
        },
        toggleMenu(state) {
            state.menuOpen = !state.menuOpen
        }
    },
    getters: {
        menuOpen: function (state) {
            return state.menuOpen
        }
    }
}

export default menu