<template>
    <div style="max-width: 20rem; margin: 0 auto;">

        <b-form @submit="newpw">
            <b-form-group id="input-group-10" label="Old Password:" label-for="input-10">
                <b-form-input id="input-10"
                              v-model="form.oldpassword"
                              type="password"
                              required
                              placeholder="Enter password"></b-form-input>
            </b-form-group>

            <b-form-group id="input-group-11" label="New Password:" label-for="input-11">
                <b-form-input id="input-11"
                              v-model="form.newpassword"
                              type="password"
                              required
                              placeholder="Enter password"></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-12" label="Confirm Password:" label-for="input-12">
                <b-form-input id="input-12"
                              v-model="form.newpasswordconf"
                              type="password"
                              required
                              placeholder="Enter password"></b-form-input>
            </b-form-group>
            
            <b-button class="mt-2" type="submit" variant="primary">Submit</b-button>

        </b-form>
    </div>
</template>

<script>
    import { Auth } from 'aws-amplify'
//    import { AmplifyEventBus } from 'aws-amplify-vue'
    export default {
        name: 'newpassword',
        data() {
            return {
                show: true,
                form: {
                    oldpassword: '',
                    newpassword: '',
                    newpasswordconf: ''
                }
            }
        },
        methods: {
            async newpw() {
                const { oldpassword, newpassword, newpasswordconf } = this.form
                if (newpassword !== newpasswordconf) {
                    alert("The new password and confirmation doesn't match!")
                } else {
                    let go = 0;
                    await Auth.currentAuthenticatedUser()
                    .then(user => {
                        return Auth.changePassword(user, oldpassword, newpassword);
                    })
                        .then(() => {
                            alert("Your password was successfully updated!")
                            go = -1
                        })
                        .catch(err => {
                            console.log(err)
                            alert("Oops! Something went wrong! Server says: " + err.message)
                        });
                    this.$router.go(go)
                }
                
                
            }
        }
    }
</script>

<style scoped>
</style>