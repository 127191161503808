<template>
    <div :style="bgImage">
        <div id="dbDiv" ref="dbDiv">
            <gridLayout :layout.sync="layout"
                        :col-num="cols"
                        :row-height="10"
                        :is-draggable="editDbMode"
                        :is-resizable="editDbMode"
                        :is-mirrored="false"
                        :vertical-compact="false"
                        :preventCollision="false"
                        :margin="[10, 10]"
                        :use-css-transforms="false"
                        :breakpoints="breakPoints"
                        :responsive="false"
                        @layout-updated="layoutUpdatedEvent">

                <gridItem v-for="item in layout"
                          :x="item.x"
                          :y="item.y"
                          :w="item.w"
                          :h="item.h"
                          :i="item.i"
                          :key="item.i"
                          drag-allow-from=".dragIcon"
                          @container-resized="resizedEvent">
                    <componentLoader :dbid="dashboard.id" :itemid="item.i" :comp="item" @removed="compRemoved">
                    </componentLoader>
                </gridItem>
            </gridLayout>
        </div>
    </div>
</template>

<script>
    import { globalStore } from '@/main.js';
    import ComponentLoader from '@/components/Dashboard/componentLoader';
    import VueGridLayout from 'vue-grid-layout';
    import { bus } from '@/main.js';
    export default {
        data() {
            return {
                windowWidth: 0,
                windowHeight: 0,
                divHeight: 0,
                layout: [],
                cols: 12
            };
        },
        computed: {
            dashboard() {
                const id = this.$route.params.id;
                let d = this.$store.getters.dashboards.filter(function (c) {
                    return c.id === id;
                })[0];
                if (typeof (d) != 'undefined') {
                    globalStore.currentPage = d.id;
                }

                return d;
            },
            editDbMode() {
                return this.$store.getters.editDbMode;
            },
            breakPoints() {
                return this.$store.getters.breakPoints;
            },
            dbSize() {
                return this.$store.getters.dbSize
            },
            bgImage() {
                //console.log('divHeight: ' + this.divHeight + ' windowHeight: ' + this.windowHeight)
                //if (typeof this.$refs.dbDiv !== 'undefined') console.log('this.$refs.dbDiv.clientHeight: ' + this.$refs.dbDiv.clientHeight)
                //let height = (this.divHeight > this.windowHeight + 56) ? this.divHeight + 'px' : (this.windowHeight - 56) + 'px'
                let height = (this.divHeight > this.windowHeight - 56) ? '100%' : 'calc(100vh - 56px)'
                //console.log('newHeight: ' + height)
                return {
                    //'backgroundImage': (typeof this.dashboard.bgImage !== 'undefined' && this.dashboard.bgImage !== '' && this.dashboard.bgImage !== 'tbd') ? 'url(' + this.$store.getters.appUrl + '/images/' + this.$store.getters.user + '/' + this.dashboard.bgImage + ')' : 'none',
                    'backgroundImage': (typeof this.dashboard.bgImage !== 'undefined' && this.dashboard.bgImage !== '' && this.dashboard.bgImage !== 'tbd') ? 'url(' + window.location.origin + '/images/' + this.$store.getters.user + '/' + this.dashboard.bgImage + ')' : 'none',
                    'height': height,
                    //'height': '100%',
                    'background-repeat': 'no-repeat',
                    'background-attachment': 'fixed',
                    'background-position': 'center',
                    '-webkit-background-size': 'cover',
                    '-moz-background-size': 'cover',
                    '-o-background-size': 'cover',
                    'background-size': 'cover'
                }
            }
        },
        components: {
            componentLoader: ComponentLoader,
            gridLayout: VueGridLayout.GridLayout,
            gridItem: VueGridLayout.GridItem
        },
        methods: {
            resizedEvent: function (i, newH, newW, newHPx, newWPx) {
                let newSize = { i: i, w: newW, h: newH, wpx: newWPx, hpx: newHPx }
                bus.$emit('itemResized', newSize)
            },
            movedEvent: function (i, newX, newY) {
                let newPos = { i: i, x: newX, y: newY }
                bus.$emit('itemMoved', newPos)
            },
            layoutUpdatedEvent: function (newLayout) {
                this.$store.commit('updateCompLayouts', { dbid: this.dashboard.id, layout: JSON.parse(JSON.stringify(newLayout)) });
            },
            getWindowSize() {
                this.windowWidth = window.innerWidth
                this.windowHeight = window.innerHeight
                let dbSize = 'xxs'
                if (this.windowWidth >= this.breakPoints.lg) {
                    dbSize = 'lg'
                } else if (this.windowWidth >= this.breakPoints.md) {
                    dbSize = 'md'
                } else if (this.windowWidth >= this.breakPoints.sm) {
                    dbSize = 'sm'
                } else if (this.windowWidth >= this.breakPoints.xs) {
                    dbSize = 'xs'
                }
                return dbSize
            },
            updateLayout(newSize) {
                let layout = []
                let storedLayout = JSON.parse(JSON.stringify(this.dashboard.layout))
                for (let item of storedLayout) {
                    if (newSize == 'xxs') {
                        layout.push({ ...item.xxs, i: item.i, name: item.name, file: item.file, config: item.config })
                        this.cols = 12
                    } else if (newSize == 'xs') {
                        layout.push({ ...item.xs, i: item.i, name: item.name, file: item.file, config: item.config })
                        this.cols = 12
                    } else if (newSize == 'sm') {
                        layout.push({ ...item.sm, i: item.i, name: item.name, file: item.file, config: item.config })
                        this.cols = 12
                    } else if (newSize == 'md') {
                        layout.push({ ...item.md, i: item.i, name: item.name, file: item.file, config: item.config })
                        this.cols = 12
                    } else if (newSize == 'lg') {
                        layout.push({ ...item.lg, i: item.i, name: item.name, file: item.file, config: item.config })
                        this.cols = 12
                    }

                }
                this.layout.splice(0, this.layout.length, ...layout)
                if (typeof this.$refs.dbDiv !== 'undefined') this.divHeight = this.$refs.dbDiv.clientHeight
                this.$store.commit('updateCompLayouts', { dbid: this.dashboard.id, layout: this.layout });
            },
            compRemoved(payload) {
                let index = this.layout.findIndex(x => x.i === payload.itemid)
                if (index > -1) {
                    this.layout.splice(index, 1)
                    this.$store.commit('deleteComp', payload)
                }
            }
        },
        created: function () {
            this.$store.commit('setDbSize', this.getWindowSize())
            this.updateLayout(this.getWindowSize())
            bus.$on('layoutUpdated', () => {
                this.updateLayout(this.getWindowSize())
            })
        },
        mounted: function () {
            this.$nextTick(function () {
                // Code that will run only after the
                // entire view has been rendered
                window.addEventListener('resize', () => {
                    //console.log('resize event')
                    this.$store.commit('setDbSize', this.getWindowSize())
                    if (typeof this.$refs.dbDiv !== 'undefined') this.divHeight = this.$refs.dbDiv.clientHeight
                });
                //this.divHeight = this.$refs.dbDiv.clientHeight
                let dbindex = this.$store.getters.dashboards.findIndex(x => x.id === this.$store.getters.activeDb)
                if (typeof dbindex !== 'undefined' && dbindex !== -1) {
                    let payload = {
                        start: this.$store.getters.timePeriod.periodStart,
                        end: this.$store.getters.timePeriod.periodEnd,
                        storageIds: this.$store.getters.dashboards[dbindex].storageIds
                    }
                    this.$store.dispatch('updateData', payload);
                    this.$store.dispatch('updateStatistics', payload);  // should be changed in the future to just update when there is statistics displayed on the dashboard
                }
                //this.newComp = this.$store.getters.dbComponents[0].name
            })
        },
        updated: function () {
            if(typeof this.$refs.dbDiv !== 'undefined') this.divHeight = this.$refs.dbDiv.clientHeight
        },
        watch: {
            dbSize(newValue) {
                this.updateLayout(newValue)
            },
            dashboard() {
                this.updateLayout(this.dbSize)
            }
        }
    }
</script>

<style scoped>
</style>