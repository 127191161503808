<template>
    <h1>Welcome, {{JSON.stringify(user.attributes, null, 2)}}</h1>
</template>

<script>
    import { Auth } from 'aws-amplify'

    export default {
        name: 'Profile',
        data() {
            return {
                user: {}
            }
        },
        beforeCreate() {
            Auth.currentAuthenticatedUser()
                .then(user => {
                    this.user = user
                })
                .catch(() => console.log('not signed in...'))
        }
    }
</script>

<style scoped>
</style>