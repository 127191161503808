import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowsAlt, faChartLine, faCog, faFilter, faHome, faSignInAlt, faSignOutAlt, faSyncAlt, faTable, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'bootstrap/dist/css/bootstrap.css';
import lodash from 'lodash';
import Vue from 'vue';
import VueLodash from 'vue-lodash';
import VueRouter from 'vue-router';
import { uuid } from 'vue-uuid';
import Vuelidate from 'vuelidate';
//import VueNativeSock from 'vue-native-websocket'
import visibility from 'vue-visibility-change';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import { createStore } from 'vuex-extensions'
import App from './App.vue';
import awsconfig from './aws-exports.js';
import Auth from './components/Auth/Auth';
import ForgotPw from './components/Auth/forgotPassw';
import NewPassword from './components/Auth/NewPassword';
import SignIn from './components/Auth/SignIn';
import SignUp from './components/Auth/SignUp';
import Dashboard from './components/Dashboard/Dashboard.vue';
import Home from './components/Home.vue';
import Profile from './components/Profile';
import Protected from './components/Protected';
import Settings from './components/Settings/settings.vue';
import config from './components/Store/config.js';
import menu from './components/Store/menu.js';
import { aggregateStatistics } from '@/assets/l4a_jsAssets.js';
export const bus = new Vue(); // Used as event-bus instance.

//const options = { name: 'lodash' } // customize the way you want to call it

//Vue.use(VueLodash, options) // options is optional
Vue.use(VueLodash, { name: 'custom', lodash: lodash })
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons)
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(visibility);
Vue.use(Vuelidate);
let ubeSocket;
export function ubeWsOpen() {
    Amplify.Auth.currentAuthenticatedUser()
        .then(async () => {
            try {
                let data = await Amplify.API.post("l4a-cbe", '/dev/createWsTicket', { body: {} })
                connectUbeSocket(data);
                console.log('ubeSocket connected!')
            } catch (err) {
                console.log(err)
            }
        })
        .catch(err => console.log(JSON.stringify(err, null, 2)))
}
export function ubeWsClose() {
    console.log('Trying to disconnect ubeSocket.')
    ubeSocket.close();
}

function connectUbeSocket(data) {
    ubeSocket = new WebSocket('wss://d0zs7kfe30.execute-api.eu-central-1.amazonaws.com/dev?ticket=' + data.ticket);
    ubeSocket.onopen = () => {
        store.commit('updateUbeSocketOpen', true)
        console.log('ubeSocket open.')
    }
    ubeSocket.onclose = async () => {
        store.commit('updateUbeSocketOpen', false)
        console.log('ubeSocket closed.')
    }
    ubeSocket.onmessage = async (data) => {
        let parsedData = JSON.parse(data.data)
        console.log(parsedData)
        if (typeof parsedData.type !== 'undefined' && parsedData.type === 'latestSamples') store.commit('addLatestSamples', parsedData.samples);
    }
    ubeSocket.onerror = async (data) => {
        let parsedData = JSON.parse(data.data)
        console.log(parsedData)
    }
}

Amplify.configure(awsconfig)
Amplify.configure({
    API: {
        endpoints: [
            {
                name: "l4a-cbe",
                endpoint: "https://htd7pvbrpa.execute-api.eu-central-1.amazonaws.com", // your API GW base url
                custom_header: async () => {
                    return { Authorization: `Bearer ${(await Amplify.Auth.currentSession()).getIdToken().getJwtToken()}` }
                }
            }
        ]
    }
});
Vue.use(AmplifyPlugin, AmplifyModules)

library.add(faUser, faHome, faChartLine, faSignInAlt, faSignOutAlt, faTable, faFilter, faCog, faTimes, faSyncAlt, faArrowsAlt)
Vue.component('font-awesome-icon', FontAwesomeIcon)

//import { Sketch } from 'vue-color'
//Vue.component('sketch-picker', Sketch)

export var globalStore = new Vue({
    data: {
        currentPage: 'Home'
    }
});
Vue.prototype.$globals = globalStore;
const vuexPersist = new VuexPersist({
    key: 'l4a-client',
    storage: window.localStorage
})
function Cols() {
    let cols = [];
    for (let c = 0; c <= 11; c++) {
        cols.push({ id: uuid.v4(), colprops: { cols: 3, md: 2, xl: 1, h: 1 }, items: [{ id: uuid.v4(), name: 'placeholder', file: 'Placeholder.vue', colprops: { cols: 3, md: 2, xl: 1, h: 1 } }] });
    }
    return cols;
}
let rows = [{ id: 0, cols: new Cols() }, { id: 1, cols: new Cols() }, { id: 2, cols: new Cols() }];
rows[0].cols[2].items[0].name = 'comp1_1';  // Just for test!!
rows[0].cols[2].items[0].file = 'comp1_1.vue';  // Just for test!!

export const initialStoreModules = {
    menu,
    config
};

//const store = new Vuex.Store({
const store = createStore(Vuex.Store, {
    state: {
        data: [],
        statistics: [],
        currentPage: 'Home',
        dbVisible: false,
        activeDb: '',
        timePeriod: {
            periodEnd: new Date(),
            periodStart: new Date(new Date() - 24 * 60 * 60 * 1000),
            period: 24
        },
        latest: true,
        ubeSocketOpen: false,
        browserHidden: false,
        wsTarget: 'undefined',
        newCollector: '',
        loadingCount: 0
    },
    mutations: {
        updateData(state, newData) {
            state.data.splice(0, state.data.length, ...newData)
        },
        addLatestSamples(state, samples) {
            for (let storage of samples) {
                let index = state.data.findIndex(x => x.placeId === storage.placeId)
                //console.log('index: ' + index)
                //console.log('storage: ' + JSON.stringify(storage.hcData))
                //console.log('hcData length: ' + storage.hcData.length)
                if (index > -1 && storage.hcData.length > 0) {
                    if (state.latest && state.timePeriod.period <= 168) {
                        // check if received data include already exising data samples.
                        //console.log('sampleTime: ' + storage.hcData[storage.hcData.length - 1][0])
                        storage.hcData.sort(function (a, b) { return a[0] - b[0] });
                        let sampleTime = new Date(storage.hcData[storage.hcData.length - 1][0])
                        if (sampleTime > state.timePeriod.periodEnd) {
                            state.timePeriod.periodEnd = new Date(storage.hcData[storage.hcData.length - 1][0])
                            state.timePeriod.periodStart = new Date(storage.hcData[storage.hcData.length - 1][0] - state.timePeriod.period * 60 * 60 * 1000)
                        }
                        let startHcInd = storage.hcData.findIndex(x => x[0] > state.data[index].hcData[state.data[index].hcData.length - 1][0])
                        let newHcData = storage.hcData.slice(startHcInd)
                        // add new data
                        state.data[index].hcData.splice(state.data[index].hcData.length, 0, ...newHcData)
                        // remove data before the period start
                        let hcIndex = state.data[index].hcData.findIndex(x => x[0] >= state.timePeriod.periodStart)
                        if (hcIndex > -1) state.data[index].hcData.splice(0, hcIndex)
                    }
                    // update latest value
                    state.data[index].timeNow = storage.hcData[storage.hcData.length - 1][0]
                    state.data[index].valueNow = storage.hcData[storage.hcData.length - 1][1]
                }
                // update statistics
                index = state.statistics.findIndex(x => x.placeId === storage.placeId)
                if (index > -1 && storage.hcData.length > 0) {

                    for (let item of storage.hcData) {
                        if (state.latest && item[1] <= state.statistics[index].statDay.minValue) {
                            state.statistics[index].statDay.minValue = item[1];
                            state.statistics[index].statDay.minTime = item[0];
                        }
                        if (state.latest && item[1] >= state.statistics[index].statDay.maxValue) {
                            state.statistics[index].statDay.maxValue = item[1];
                            state.statistics[index].statDay.maxTime = item[0];
                        }
                        if (item[1] <= state.statistics[index].statMonth.minValue) {
                            state.statistics[index].statMonth.minValue = item[1];
                            state.statistics[index].statMonth.minTime = item[0];
                        }
                        if (item[1] >= state.statistics[index].statMonth.maxValue) {
                            state.statistics[index].statMonth.maxValue = item[1];
                            state.statistics[index].statMonth.maxTime = item[0];
                        }
                        if (item[1] <= state.statistics[index].statYear.minValue) {
                            state.statistics[index].statYear.minValue = item[1];
                            state.statistics[index].statYear.minTime = item[0];
                        }
                        if (item[1] >= state.statistics[index].statYear.maxValue) {
                            state.statistics[index].statYear.maxValue = item[1];
                            state.statistics[index].statYear.maxTime = item[0];
                        }
                    }
                }

            }
            bus.$emit('newData')
        },
        updateStatistics(state, newStat) {
            state.statistics.splice(0, state.statistics.length, ...newStat)
        },
        updateCurrentPage(state, page) {
            state.currentPage = page;
        },
        updateTimePeriod(state, newPeriod) {
            console.log(newPeriod)
            let periodEnd = new Date(newPeriod.periodEnd)
            let periodStart = new Date(newPeriod.periodEnd)
            if (newPeriod.period == 720) {
                periodEnd.setMonth(periodEnd.getMonth() + newPeriod.add, periodEnd.getDate());
                periodStart.setMonth(periodStart.getMonth() + newPeriod.add - 1, periodStart.getDate());
            } else if (newPeriod.period == 8760) {
                periodEnd.setFullYear(periodEnd.getFullYear() + newPeriod.add, periodEnd.getMonth(), periodEnd.getDate());
                periodStart.setFullYear(periodStart.getFullYear() + newPeriod.add - 1, periodStart.getMonth(), periodStart.getDate());
            } else {
                periodEnd = new Date(periodEnd.getTime() + newPeriod.add * newPeriod.period * 60 * 60 * 1000)
                periodStart = new Date(periodStart.getTime() + (newPeriod.add - 1) * newPeriod.period * 60 * 60 * 1000)
            }
            state.timePeriod.periodStart = new Date(periodStart)
            state.timePeriod.periodEnd = new Date(periodEnd)
            state.timePeriod.period = newPeriod.period;
        },
        updateDbVisible(state, status) {
            state.dbVisible = status
        },
        updateActiveDb(state, id) {
            state.activeDb = id
        },
        resetState(state) {
            Vue._.forOwn(initialStoreModules, (value, key) => {
                state[key] = Vue._.cloneDeep(value.state);
            });
        },
        latest(state, latest) {
            state.latest = latest
        },
        updateBrowserHidden(state, hidden) {
            state.browserHidden = hidden
        },
        updateUbeSocketOpen(state, wsOpen) {
            state.ubeSocketOpen = wsOpen
        },
        updateNewCollector(state, collector) {
            state.newCollector = collector;
        },
        loadingStarted(state) {
            state.loadingCount++
        },
        loadingEnded(state) {
            if (state.loadingCount > 0) state.loadingCount--
        }
    },
    actions: {
        async updateData({ commit, state }, payload) {
            if (state.latest) commit('updateTimePeriod', { periodEnd: new Date(), period: state.timePeriod.period, add: 0 })
            let body = {
                placeIdList: payload.storageIds,
                startTime: new Date(state.timePeriod.periodStart),
                endTime: new Date(state.timePeriod.periodEnd),
                statDate: new Date(state.timePeriod.periodStart),  // Beh�vs b�de den h�r och n�sta rad??
                statTime: new Date(state.timePeriod.periodEnd),
                now: new Date()
            }
            Amplify.Auth.currentAuthenticatedUser()
                .then(async () => {
                    commit('loadingStarted')
                    try {
                        console.log(body)
                        let data = await Amplify.API.post("l4a-cbe", '/dev/getdatafromts', { body: body })
                        commit('updateData', data.body)
                        commit('loadingEnded')
                        if (state.loadingCount == 0) {  // Data and statistics are usually updated in parralell. Which ever is ready last will call aggregateStatistics
                            let newStat = await aggregateStatistics(state.data, state.statistics, new Date(state.timePeriod.periodEnd));
                            commit('updateStatistics', newStat);
                            bus.$emit('newStatistics')
                        }
                        bus.$emit('newData')
                        return data
                    } catch (err) {
                        console.log(JSON.stringify(err, null, 2))
                        commit('loadingEnded')
                    }

                })
                .catch(err => console.log(JSON.stringify(err, null, 2)))
        },
        //async addData({ commit }, payload) {
        //    Amplify.Auth.currentAuthenticatedUser()
        //        .then(async () => {
        //            try {
        //                let body = {
        //                    userId: '4b6d59f7-82b0-40ae-9e13-03bea93ee30b', // To be removed. Omly for testing!! Must also change in Lambda function to use API GW mapped userId.
        //                    placeIdList: payload.storageIds,
        //                    startTime: new Date(new Date(payload.start).getTime() - 4 * 60 * 1000), // get 5 min data to avoid missing data due to transfere delays. Too old data removed in commit.
        //                    endTime: new Date(payload.end),
        //                    statDate: new Date(payload.start),
        //                    now: new Date()
        //                }
        //                console.log(body)
        //                let data = await Amplify.API.post("l4a-cbe", '/dev/getData_v4', { body: body })
        //                commit('addData', data.places)
        //                bus.$emit('newData')
        //                console.log(data)
        //                return data
        //            } catch (err) {
        //                console.log(err)
        //                //bus.$emit('loading', false)
        //            }

        //        })
        //        .catch(err => console.log(JSON.stringify(err, null, 2)))

        //},
        async updateStatistics({ commit, state }, payload) {
            //console.log(new Date().getTimezoneOffset())
            if (state.latest) commit('updateTimePeriod', { periodEnd: new Date(), period: state.timePeriod.period, add: 0 })
            let body = {
                placeIdList: payload.storageIds,
                startTime: new Date(state.timePeriod.periodStart),
                endTime: new Date(state.timePeriod.periodEnd),
                statDate: new Date(state.timePeriod.periodStart),  // Beh�vs b�de den h�r och n�sta rad??
                statTime: new Date(state.timePeriod.periodEnd),
                now: new Date(),
                clientOffset: new Date().getTimezoneOffset()
            }
            Amplify.Auth.currentAuthenticatedUser()
                .then(async () => {
                    bus.$emit('loadingStat', true)
                    commit('loadingStarted')
                    try {
                        //console.log(body)
                        let stat = await Amplify.API.post("l4a-cbe", '/dev/getStatistics', { body: body })
                        //console.log(JSON.stringify(stat, null, 2))
                        commit('updateStatistics', stat.body)
                        bus.$emit('loadingStat', false)
                        commit('loadingEnded')
                        if (state.loadingCount == 0) {  // Data and statistics are usually updated in parralell. Which ever is ready last will call aggregateStatistics
                            let newStat = await aggregateStatistics(state.data, state.statistics, new Date(state.timePeriod.periodEnd));
                            commit('updateStatistics', newStat);
                            bus.$emit('newStatistics')
                        }
                        return stat
                    } catch (err) {
                        console.log(JSON.stringify(err, null, 2))
                        bus.$emit('loadingStat', false)
                        commit('loadingEnded')
                    }

                })
                .catch(err => console.log(JSON.stringify(err, null, 2)))
        },
    },
    getters: {
        data: function (state) {
            return state.data
        },
        statistics: function (state) {
            return state.statistics
        },
        currentPage: function (state) {
            return state.currentPage
        },
        timePeriod: function (state) {
            return state.timePeriod
        },
        dbVisible: function (state) {
            return state.dbVisible
        },
        activeDb: function (state) {
            return state.activeDb
        },
        latest: function (state) {
            return state.latest
        },
        browserHidden: function (state) {
            return state.browserHidden
        },
        ubeSocketOpen: function (state) {
            return state.ubeSocketOpen
        },
        newCollector: function (state) {
            return state.newCollector
        },
        loadingCount: function (state) {
            return state.loadingCount
        }
    },
    modules: Vue._.cloneDeep(initialStoreModules),
    /*modules: {
        menu,
        config
    },*/
    plugins: [vuexPersist.plugin]
})
// Make some inititializations of store in case browser has a cached version when loading
store.commit('updateUbeSocketOpen', false)
//ubeSocket.close();


let routes = [
    { path: '/', name: 'Home', component: Home },
    { path: '/dashboards/:id', name: 'Dashboard', component: Dashboard, meta: { requiresAuth: true } },
    { path: '/auth', name: 'Authentication', component: Auth },
    { path: '/signin', name: 'Sign In', component: SignIn },
    { path: '/signup', name: 'Sign Up', component: SignUp },
    { path: '/forgotpw', name: 'Request new password', component: ForgotPw },
    { path: '/newpassword', name: 'Change password', component: NewPassword },
    { path: '/protected', name: 'Development test page', component: Protected, meta: { requiresAuth: true } },
    { path: '/profile', name: 'Profile (Test page)', component: Profile, meta: { requiresAuth: true } },
    { path: '/settings', name: 'Settings', component: Settings, meta: { requiresAuth: true } }
]
export const router = new VueRouter({ mode: 'hash', routes: routes });

router.beforeResolve((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        Amplify.Auth.currentAuthenticatedUser().then(() => {
            next()
        }).catch(() => {
            next({
                path: '/signin'
            });
        });
    }
    next()
})

new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app');
