<template>
    <div style="max-width: 20rem; margin: 0 auto;">

        <b-form @submit="forgotPw" v-if="formState === 'forgotPw' && show">
            <b-form-group id="input-group-7"
                          label="Email address:"
                          label-for="input-7"
                          description="We'll never share your email with anyone else.">
                <b-form-input id="input-7"
                              v-model="form.email"
                              type="email"
                              required
                              placeholder="Enter email"></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="primary">New Passw</b-button>
        </b-form>
        <b-form @submit="confirmNewPw" v-if="formState === 'confirmNewPw' && show">
            <b-form-group id="input-group-8" label="Confirmation code:" label-for="input-2">
                <b-form-input id="input-8"
                              v-model="form.authCode"
                              type="text"
                              required
                              placeholder="Enter code"></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-9" label="New Password:" label-for="input-2">
                <b-form-input id="input-9"
                              v-model="form.password"
                              type="password"
                              required
                              placeholder="Enter password"></b-form-input>
            </b-form-group>

            <b-button type="submit" variant="primary">Confirm</b-button>
        </b-form>

        <!--<div class='formcontainer'>
            <input v-model='form.username' class='input' />
            <input type='password' v-model='form.password' class='input' />
            <button v-on:click='signIn' class='button'>Sign In</button>
        </div>-->
    </div>
</template>

<script>
    import { Auth } from 'aws-amplify'
//    import { AmplifyEventBus } from 'aws-amplify-vue'
    export default {
        name: 'forgotPassword',
        props: ['toggle'],
        data() {
            return {
                formState: 'forgotPw',
                show: true,
                form: {
                    email: ''
                }
            }
        },
        methods: {
            async forgotPw() {
                const { email } = this.form
                await Auth.forgotPassword(email)
                    .then(() => {
                        this.formState = 'confirmNewPw'
                    })
                    .catch(err => {
                        console.log("error after Auth.forgotPassword: " + JSON.stringify(err, null, 2));
                        alert("Oops! Something went wrong! Server says: " + err.message);
                    });
                
            },
            async confirmNewPw() {
                const { email, authCode, password } = this.form
                await Auth.forgotPasswordSubmit(email, authCode, password)
                    .then(() => {
                        alert('Successfully changed password! You can now sign in.')
                        if (this.$router.path !== '/signin') this.$router.push('/signin')
                    })
                    .catch(err => {
                        console.log("error after Auth.forgotPasswordSubmit: " + JSON.stringify(err, null, 2));
                        alert("Oops! Something went wrong! Server says: " + err.message);
                    });

            }

        }
    }
</script>

<style scoped>
    /*.formcontainer {
        display: flex;
        flex-direction: column;
        width: 500px;
        margin: 0 auto;
    }

    .input {
        margin-bottom: 7px;
        height: 38px;
        border: none;
        outline: none;
        border-bottom: 2px solid #ddd;
        font-size: 20px;
    }

    .button {
        height: 45px;
        border: none;
        outline: none;
        background-color: #dddddd;
        margin-top: 8px;
        cursor: pointer;
        font-size: 18px;
    }

        .button:hover {
            opacity: .7
        }*/
</style>