<template>
    <b-container>
        <b-row>
            <b-col>
                <!--<resource-filter />-->
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    //import filter from '@/components/Settings/filter.vue'
    export default {
        data() {
            return {
                //selectedCollector: '',
                
            };
        },
        components: {
            //'resource-filter': filter
        }
    }
</script>

<style scoped>
</style>