<template>
    <div>
        <h1>Development test page</h1>
        <b-form>
            <b-button ref="bt1" class="mt-2" v-on:click="testAddDb" variant="primary">Add dashboard</b-button>
            <b-form-select v-model="selected" :options="options"></b-form-select>
            <b-button class="mt-2" v-on:click="testRmvDb" variant="primary">Remove dashboard</b-button>
            <b-button class="mt-2" v-on:click="testGetData" variant="primary">Test getData</b-button>
        </b-form>
        <p>{{selected}}</p>
        <br />
        <p>w: {{windowWidth}}</p>
        <p>h: {{windowHeight}}</p>
        <p>btw: {{btWidth}}</p>
        <p>bth: {{btHeight}}</p>
    </div>

</template>

<script>
    import { getData } from '@/assets/l4a_jsAssets.js';
    
    export default {
        name: 'protected',
        data() {
            return {
                selected: null,
                windowHeight: 0,
                windowWidth: 0,
                btWidth: 0,
                btHeight: 0
            };
        },
        computed: {
            options: function () {
                let options = []
                for (let dashboard of this.$store.getters.dashboards) {
                    options.push({ "value": dashboard.id, "text": dashboard.name })
                }
                return options
            }
        },
        methods: {
            testAddDb() {
                this.$store.commit('addDashboard')
            },
            testRmvDb() {
                console.log("selected: " + this.selected)
                this.$store.commit('removeDashboard', this.selected)
            },
            testGetData() {
                let endTime = new Date().getTime()
                let startTime = endTime - 10*60*1000
                console.log(getData(startTime, endTime, ['f0eab8d8-caf7-4d69-ba30-0b33245ee169', '2ef4588b-d9c5-40f0-859b-ba1524fee8d3']))
            }
        },
        mounted() {
            this.windowHeight = window.innerHeight;
            this.windowWidth = window.innerWidth;
            this.btWidth = this.$refs.bt1.clientWidth;
            this.btHeight = this.$refs.bt1.clientHeight;
            this.$nextTick(() => {
                window.addEventListener('resize', () => {
                    this.windowHeight = window.innerHeight;
                    this.windowWidth = window.innerWidth
                });
            });
            // below is to test local connection to collector
            //const testSocket = new Vue()
            this.$store.commit('updateWsTarget', 'localCollector');
            this.$disconnect();
            
        },
        beforeDestroy() {
            this.$store.commit('updateWsTarget', 'ube');
            this.$disconnect();
            console.log('leaving development page...')
            
        }

    }
</script>

<style scoped>
</style>